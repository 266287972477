import { ILocaleField, LocaleField } from "./locale-field";

export interface IField {
  ui_key: string;
  localeTitle: ILocaleField[];
  localeHelp: ILocaleField[];
}

export class Field implements IField {
  ui_key: string;
  localeTitle: LocaleField[];
  localeHelp: LocaleField[];

  getTitleByLang(lang: string): string {
    return this.localeTitle.find(x => x.langCode === lang)?.text || '';
  }

  getHelpByLang(lang: string): string {
    return this.localeHelp.find(x => x.langCode === lang)?.text || '';
  }

  constructor(data: IField) {
    this.ui_key = data.ui_key;
    this.localeTitle = data.localeTitle.map(x => new LocaleField(x));
    this.localeHelp = data.localeHelp.map(x => new LocaleField(x));
  }
}
