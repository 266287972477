import { Injectable } from '@angular/core';
import { ApiService } from './api-service.service';
import { environment } from '../../../environments/environment';
import { map, Observable } from 'rxjs';
import { IIndicatorCommand } from '../models/commands';
import {IIndicator, Indicator} from "../models/entities/organizations";
import {IEntityList} from "../models/entities/entity-list";

@Injectable({
  providedIn: 'root',
})
export class IndicatorService extends ApiService {
  private readonly url = `${environment.api.baseUrl}/indicators`;

  getById(id: string, langCode: string): Observable<Indicator> {
    const url = `${this.url}/${id}?languageCode=${langCode}`;
    return this.get<IIndicator>(url).pipe(
      map((i: IIndicator) => new Indicator(i))
    );
  }

  getAllPrimary(
    langCode: string,
    filters: string | null = null
  ): Observable<IEntityList<IIndicator>> {
    const url = `${this.url}?languageCode=${langCode}${filters ? `&filterBase64=${filters}` : ''}`;
    return this.get<IEntityList<IIndicator>>(url);
  }

  getAllSecondary(
    langCode: string,
    filters: string | null = null
  ): Observable<IEntityList<IIndicator>> {
    const url = `${this.url}?languageCode=${langCode}${filters ? `&filterBase64=${filters}` : ''}`;
    return this.get<IEntityList<IIndicator>>(url);
  }

  toggleIsActive(
    id: string,
    command: { isActive: boolean }
  ): Observable<{ isActive: boolean }> {
    return this.patch<{ isActive: boolean }>(`${this.url}/${id}`, command);
  }

  create(indicator: IIndicatorCommand): Observable<Indicator> {
    return this.post<IIndicator>(this.url, indicator).pipe(
      map((i: IIndicator) => new Indicator(i))
    );
  }

  update(
    id: string,
    indicator: IIndicatorCommand
  ): Observable<Indicator> {
    return this.put<IIndicator>(`${this.url}/${id}`, indicator).pipe(
      map((i: IIndicator) => new Indicator(i))
    );
  }

  remove(id: string): Observable<boolean> {
    return this.delete(`${this.url}/${id}`);
  }
}
