import { Injectable } from '@angular/core';
import { ApiService } from './api-service.service';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { IContentTargetCommand } from '../../models/content-target-command.model';
import {ITarget, Target} from '../models/entities/contents';
import {map} from "rxjs/operators";
import {IPartialSelection} from "./partial-selections/partial-selection";

@Injectable({
  providedIn: 'root',
})
export class ContentService extends ApiService {
  private readonly url = `${environment.api.baseUrl}/contents`;

  upsertTarget(
    contentId: string,
    target: IContentTargetCommand
  ): Observable<IContentTargetCommand> {
    const url = `${this.url}/${contentId}/target`;
    return this.post<IContentTargetCommand>(url, target);
  }

  getTarget(contentId: string, langCode: string): Observable<{ target: ITarget }> {
    const url = `${this.url}/${contentId}/target?languageCode=${langCode}`;
    return this.get<{ target: Target, selections?: IPartialSelection<any>[] }>(url).pipe(
      map(response => ({
        target: new Target(response.target, response.selections)
      }))
    );
  }

  toggleIsActive(
    id: string,
    command: { isActive: boolean }
  ): Observable<{ isActive: boolean }> {
    return this.patch<{ isActive: boolean }>(`${this.url}/${id}`, command);
  }

  deleteTarget(contentId: string): Observable<boolean> {
    const url = `${this.url}/${contentId}/target`;
    return this.delete<boolean>(url)
  }
}
