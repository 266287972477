import { CompanyLegalStatus, CompanyScope, CompanySize } from "./company";

export class CompanyPropertiesData {
  static scopes: CompanyScope[] = [
    new CompanyScope({ id: 'municipal', name: 'municipal', createdAt: new Date(), updatedAt: new Date() }),
    new CompanyScope({ id: 'provincial', name: 'provincial', createdAt: new Date(), updatedAt: new Date() }),
    new CompanyScope({ id: 'autonomous', name: 'autonomous', createdAt: new Date(), updatedAt: new Date() }),
    new CompanyScope({ id: 'national', name: 'national', createdAt: new Date(), updatedAt: new Date() }),
    new CompanyScope({ id: 'european', name: 'european', createdAt: new Date(), updatedAt: new Date() }),
    new CompanyScope({ id: 'international', name: 'international', createdAt: new Date(), updatedAt: new Date() }),
  ];

  static getAllScopes(): CompanyScope[] {
    return this.scopes;
  }

  static sizes: CompanySize[] = [
    new CompanySize({ id: 'S', name: 'S', createdAt: new Date(), updatedAt: new Date() }),
    new CompanySize({ id: 'M', name: 'M', createdAt: new Date(), updatedAt: new Date() }),
    new CompanySize({ id: 'L', name: 'L', createdAt: new Date(), updatedAt: new Date() }),
    new CompanySize({ id: 'XL', name: 'XL', createdAt: new Date(), updatedAt: new Date() }),
  ];

  static getAllSizes(): CompanySize[] {
    return this.sizes;
  }

  static legalStatuses: CompanyLegalStatus[] = [
    new CompanyLegalStatus({ id: 'SLU', name: 'SLU', createdAt: new Date(), updatedAt: new Date() }),
    new CompanyLegalStatus({ id: 'SL', name: 'SL', createdAt: new Date(), updatedAt: new Date() }),
    new CompanyLegalStatus({ id: 'SA', name: 'SA', createdAt: new Date(), updatedAt: new Date() }),
    new CompanyLegalStatus({ id: 'SCP', name: 'SCP', createdAt: new Date(), updatedAt: new Date() }),
  ];

  static getAllLegalStatuses(): CompanyLegalStatus[] {
    return this.legalStatuses;
  }
}
