import {signal} from "@angular/core";
import {TranslateService} from "@ngx-translate/core";

export class TranslationsViewModel {
  translations = signal<{ [x: string]: string }>({});

  constructor(private translate: TranslateService, keys?: string[], instant: boolean = false) {
    if(keys && keys.length > 0) {
      if (instant) {
        this.setInstantKeys(keys);
      } else {
        this.setStreamKeys(keys);
      }
    }
  }

  setStreamKeys(keys: string[]) {
    this.translate
      .stream(keys)
      .subscribe((translations: any) => {
        this.translations.set(translations);
      });
  }

  setInstantKeys(keys: string[]) {
    this.translate.onLangChange.subscribe(() => {
      this.translations.set(this.getKeys(keys));
    });
  }

  getKeys(keys:string[]): { [x: string]: string } {
    return this.translate.instant(keys);
  }
}
