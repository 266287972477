import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';
import { ApiService } from '.';
import {map} from "rxjs/operators";
import { IContentCommand } from '../models/commands';
import {IEntityList} from "../models/entities/entity-list";
import {IResource, Resource} from "../models/entities/contents";

@Injectable({
  providedIn: 'root',
})
export class ResourcesService extends ApiService {
  private readonly url = `${environment.api.baseUrl}/resources`;

  getAll(
    langCode: string,
    filters: string | null = null
  ): Observable<IEntityList<IResource>> {
    const url = `${this.url}?languageCode=${langCode}${filters ? `&filterBase64=${filters}` : ''}`;
    return this.get<IEntityList<IResource>>(url);
  }

  getMy(
    langCode: string,
    filters: string | null = null
  ): Observable<IEntityList<IResource>> {
    const url = `${this.url}/me?languageCode=${langCode}${filters ? `&filterBase64=${filters}` : ''}`;
    return this.get<IEntityList<Resource>>(url).pipe(
      map(entityList => ({
        ...entityList,
        items: entityList.listObject.map(item => new Resource(item))
      }))
    );
  }

  getById(id: string, langCode: string): Observable<IResource> {
    const url = `${this.url}/${id}?languageCode=${langCode}`;
    return this.get<IResource>(url);
  }

  create(resource: IContentCommand): Observable<Resource> {
    return this.post<Resource>(this.url, resource).pipe(
      map(resource => new Resource(resource))
    );
  }

  update(id: string, resource: IContentCommand): Observable<Resource> {
    return this.put<IResource>(`${this.url}/${id}`, resource).pipe(
      map(resource => new Resource(resource))
    );
  }

  remove(id: string): Observable<boolean> {
    return this.delete(`${this.url}/${id}`);
  }
}
