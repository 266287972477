import { BaseFile, IBaseFile } from "../base-file";

export interface IOrganizationUnitFile extends IBaseFile {
}

export class OrganizationUnitFile extends BaseFile implements IOrganizationUnitFile {

  constructor(data: IOrganizationUnitFile) {
    super(data);
  }
}
