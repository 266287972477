const LONG_MONTHS: { [key: string]: string } = {
  "enero": "xaneiro",
  "febrero": "febreiro",
  "marzo": "marzo",
  "abril": "abril",
  "mayo": "maio",
  "junio": "xuño",
  "julio": "xullo",
  "agosto": "agosto",
  "septiembre": "setembro",
  "octubre": "outubro",
  "noviembre": "novembro",
  "diciembre": "decembro"
};

const SHORT_MONTHS: { [key: string]: string } = {
  "ene": "xan",
  "feb": "feb",
  "mar": "mar",
  "abr": "abr",
  "may": "mai",
  "jun": "xuñ",
  "jul": "xul",
  "ago": "ago",
  "sep": "set",
  "oct": "out",
  "nov": "nov",
  "dic": "dec"
};

export function translateMonthDate(date: string, shortMonth: boolean = false): string {
  const months = shortMonth ? SHORT_MONTHS : LONG_MONTHS;
  const monthsRegex = new RegExp(Object.keys(months).join('|'), 'gi');

  return date.replace(monthsRegex, (match) => {
    const lowerCaseMatch = match.toLowerCase();
    return months[lowerCaseMatch] || match;
  });
} 