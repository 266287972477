import { inject } from "@angular/core";
import { ActivatedRouteSnapshot, ResolveFn } from "@angular/router";
import { DynamicTitleService } from "../../services/dynamic-title.service";

export const titleResolver: ResolveFn<void> = (route: ActivatedRouteSnapshot) => {
  const titleService = inject(DynamicTitleService);
  const translationKey = route.data['titleTranslationKey'];

  if (translationKey) {
    titleService.setDynamicTitle(translationKey);
  } else {
    console.log('No translation key found for title resolver');
  }
  return;
}; 