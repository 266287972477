import {AssistanceTopic} from "./assistance";

export class AssistancePropertiesData {
  static assistanceTopics: AssistanceTopic[] = [
    new AssistanceTopic({id: 'mitigation', name: 'mitigation', createdAt: new Date(), updatedAt: new Date()}),
    new AssistanceTopic({id: 'adaptation', name: 'adaptation', createdAt: new Date(), updatedAt: new Date()}),
    new AssistanceTopic({id: 'innovation', name: 'innovation', createdAt: new Date(), updatedAt: new Date()}),
    new AssistanceTopic({id: 'governance', name: 'governance', createdAt: new Date(), updatedAt: new Date()}),
  ];

  static getAllAssistanceTopics(): AssistanceTopic[] {
    return this.assistanceTopics;
  }
}
