import { Entity, IEntity } from '../entity';
import { ILanguage, Language } from '../translations';
import { OrganizationUnitTypeData } from './organization-unit-type-data';
import { OrganizationUnitTypeType } from '../../types';
import { IOrganizationUnitFile, OrganizationUnitFile } from './organization-unit-file';

export interface IOrganizationUnit extends IEntity {
  id: string;
  name: string;
  language: ILanguage | undefined;
  type: string;
  editable: boolean;
  climateProfileFile: IOrganizationUnitFile | null;
  challengesAndConcernsFile: IOrganizationUnitFile | null;
}

export class OrganizationUnit extends Entity implements IOrganizationUnit {
  name: string;
  language: Language | undefined;
  type: OrganizationUnitTypeType;
  climateProfileFile: OrganizationUnitFile | null;
  challengesAndConcernsFile: OrganizationUnitFile | null;

  get hasClimateProfileFile(): boolean {
    return this.climateProfileFile !== null;
  }

  get hasChallengesAndConcernsFile(): boolean {
    return this.challengesAndConcernsFile !== null;
  }

  get editable() {
    return this.type !== OrganizationUnitTypeData.getByName('xunta')?.name &&
      this.type !== OrganizationUnitTypeData.getByName('technicalOffice')?.name;
  }

  constructor(data: IOrganizationUnit) {
    super(data);
    this.name = data.name;
    this.type = data.type;
    this.language = data.language != null
      ? new Language(data.language)
      : undefined
    this.climateProfileFile = data.climateProfileFile != null
      ? new OrganizationUnitFile(data.climateProfileFile)
      : null;
    this.challengesAndConcernsFile = data.challengesAndConcernsFile != null
      ? new OrganizationUnitFile(data.challengesAndConcernsFile)
      : null;
  }
}
