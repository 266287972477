import {BaseFile, IBaseFile} from "../../base-file";

export interface IResourceFile extends IBaseFile {
  order: number;
  isNew: boolean;
}

export class ResourceFile extends BaseFile implements IResourceFile {
  isNew: boolean;
  order: number;

  constructor(data: IResourceFile) {
    super(data);
    this.isNew = data.isNew ?? false;
    this.order = data.order;
  }
}
