import { OrganizationUnitType } from './organization-unit-type';
import { OrganizationUnitTypeType } from '../../types';

export class OrganizationUnitTypeData {
  static types: OrganizationUnitType[] = [
    new OrganizationUnitType({ id:"company", name: "company", createdAt: new Date(), updatedAt: new Date() }),
    new OrganizationUnitType({ id:"cityhall", name: "cityhall", createdAt: new Date(), updatedAt: new Date() }),
    new OrganizationUnitType({ id:"community", name: "community", createdAt: new Date(), updatedAt: new Date() }),
    new OrganizationUnitType({ id:"technicalOffice", name: "technicalOffice", createdAt: new Date(), updatedAt: new Date() }),
    new OrganizationUnitType({ id:"xunta", name: "xunta", createdAt: new Date(), updatedAt: new Date() }),
  ];

  static getAllTypes(): OrganizationUnitType[] {
    return OrganizationUnitTypeData.types;
  }

  static getByName(name: OrganizationUnitTypeType): OrganizationUnitType | undefined {
    return OrganizationUnitTypeData.types.find(type => type.name === name);
  }
}
