import { Injectable, Signal } from '@angular/core';
import { map, Observable, switchMap } from 'rxjs';

import { environment } from '../../../environments/environment';
import { ApiService } from '.';
import { toSignal } from '@angular/core/rxjs-interop';
import {
  IOrganizationUnit,
  IOrganizationUnitFile,
  IOrganizationUnitType,
  OrganizationUnit,
  OrganizationUnitType
} from '../models/entities/organizations';
import {IEntityList} from "../models/entities/entity-list";

@Injectable({
  providedIn: 'root',
})
export class OrganizationUnitService extends ApiService {
  private readonly url = `${environment.api.baseUrl}/organization-units`;

  public organization: Signal<OrganizationUnit | undefined> = toSignal(
    this.getByUser()
  );

  getByUser(): Observable<OrganizationUnit> {
    return this.get<IOrganizationUnit>(`${this.url}/me`).pipe(
      map((data) => new OrganizationUnit(data))
    );
  }

  getTypes(): Observable<OrganizationUnitType[]> {
    return this.get<Array<IOrganizationUnitType>>(`${this.url}/types`).pipe(
      map((types) => types.map((type) => new OrganizationUnitType(type)))
    );
  }

  getById(id: string): Observable<OrganizationUnit> {
    return this.get<IOrganizationUnit>(`${this.url}/${id}`).pipe(
      map((data) => new OrganizationUnit(data))
    );
  }

  getAllByType(
    type: string | null = null,
    filters: string | null = null
  ): Observable<IEntityList<OrganizationUnit>> {
    const params = new URLSearchParams();
    if (type) {
      params.set('type', type);
    }
    if (filters) {
      params.set('filterBase64', filters);
    }

    const url = `${this.url}${params.toString() ? '?' + params.toString() : ''}`;
    return this.get<IEntityList<OrganizationUnit>>(url);
  }

  uploadFile(organizationId: string, type: string, file: File): Observable<IOrganizationUnitFile> {
    return this.getHeadersFile().pipe(
      switchMap((headers) => {
        const formData = new FormData();
        formData.append('file', file);

        return this.post<IOrganizationUnitFile>(
          `${this.url}/${organizationId}/file/${type}`,
          formData,
          headers
        );
      })
    );
  }

  deleteFile(organizationId: string, type: string): Observable<boolean> {
    return this.delete(`${this.url}/${organizationId}/file/${type}`);
  }
}
