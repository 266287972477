import {ActivityType, IActivityType} from './activity-type';
import {ActivityFile, IActivityFile} from "./activity-file";
import { Content, IContent } from '../content';
import {Entity, IEntity} from "../../entity";
import { GaliciaDatePipe } from '../../../../Utils/pipes/galicia-date.pipe';

export interface IActivity extends IContent {
  type: IActivityType;
  slots: Array<ISlot>;
  isSubscribed: boolean;
  subscribedSlot?: IUserSlot;
  file: IActivityFile | null;
  get hasFile(): boolean;
  get slotDates(): string[];
  get formattedSlotDates(): string;
}

export interface ISlot extends IEntity {
  endTime: string;
  startTime: string;
  capacity: number;
  userSlot?: IUserSlot;
}

export class Slot extends Entity implements ISlot {
  endTime: string;
  startTime: string;
  capacity: number;
  userSlot?: IUserSlot

  constructor(data: ISlot) {
    super(data);
    this.endTime = data.endTime;
    this.startTime = data.startTime;
    this.capacity = data.capacity;
    if (data.userSlot) {
      this.userSlot = new UserSlot(data.userSlot);
    }
  }
}

export class Activity extends Content implements IActivity {
  type: ActivityType;
  slots: Array<Slot>;
  isSubscribed: boolean;
  subscribedSlot?: IUserSlot;
  file: IActivityFile | null;

  get hasFile(): boolean {
    return this.file !== null;
  }
  get slotDates(): string[] {
    if (!this.slots || this.slots.length === 0) {
      return [];
    }

    const uniqueDates = new Set<string>();
    this.slots.forEach(slot => {
      const date = new Date(slot.startTime).toISOString().split('T')[0];
      uniqueDates.add(date);
    });
    return Array.from(uniqueDates);
  }

  get formattedSlotDates(): string {
    return this.getFormattedSlotDates('es');
  }

  constructor(data: IActivity) {
    super(data);
    this.type = new ActivityType(data.type);
    this.slots = data.slots.map((el) => {
      return new Slot(el);
    });
    this.isSubscribed = data.isSubscribed;
    const slotIndex = this.slots.findIndex(x => x.userSlot);
    if (slotIndex > -1) {
      this.subscribedSlot = this.slots[slotIndex].userSlot;
    }
    this.file = (data.file) ? new ActivityFile(data.file) : null;
  }

  getFormattedSlotDates(lang: string): string {
    const dates = this.slotDates;
    if (dates.length === 0) {
      return '';
    }

    return dates
      .map(date => new Date(date))
      .sort((a, b) => a.getTime() - b.getTime())
      .map(date => new GaliciaDatePipe().transform(date, lang, true))
      .join('/');
  }
}

export interface IUserSlot {
  timeSlotId: number;
  firstName?: string;
  lastName?: string;
}

export class UserSlot implements IUserSlot {
  timeSlotId: number;
  firstName?: string | undefined;
  lastName?: string | undefined;

  constructor(data: IUserSlot) {
    this.timeSlotId = data.timeSlotId;
    this.firstName = data.firstName;
    this.lastName = data.lastName;
  }
}
