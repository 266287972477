import {Entity} from "../models/entities/entity";

export function getFlagImage(lang: string): string {
  return `./images/flag-${lang}.png`;
}

export function getMinScrollHeight(list: Entity[], itemHeight: number,
  numItemsDefault: number = 5, padding: number = 25)
  : { style: { 'min-height': string } } {
  const numItems = list.length;
  const height = numItems >= numItemsDefault || numItems === 0
    ? numItemsDefault * itemHeight
    : numItems * itemHeight;

  return {
    style: {
      'min-height': `${height + padding}px`
    }
  };
}

export function getScrollerHeight(list: any[], itemHeight: number = 40,
  padding: number = 20): string {
    if (list.length > 0) {
      const totalHeight = list.reduce((acc, item) => {
        const textLength = item.name.length;
        const multiplier = Math.ceil(textLength / 40);
        const dynamicHeight = multiplier === 1
          ? multiplier * itemHeight
          : multiplier * (itemHeight-5);
        return acc + dynamicHeight;
      }, 0);
      return `${totalHeight + padding}`;
    }
    return `${itemHeight + padding}`;
}

export function getMaxItemHeight(
  list: any[],
  itemHeightPerLine: number = 25,
  lineLength: number = 20
): number {
  const padding = 25;
  const minItemHeight = 55;
  if (list.length === 0) {
    return itemHeightPerLine + padding; 
  }

  const maxHeight = list.reduce((max, item) => {
    const textLength = item.name.length;

    const lineCount = Math.ceil(textLength / lineLength);
    const itemHeight = lineCount * itemHeightPerLine;

    return Math.max(max, itemHeight);
  }, 0);

  return Math.max(maxHeight, minItemHeight);
} 

export function filterSelected<T extends Entity>(list: T[], selectedIds: string[]): T[] {
  return list.filter((item) => selectedIds.includes(item.id));
}
