import { Entity, IActivable, IEntity } from '../../entity';
import { CommunityType, ICommunityType } from './community-type';
import { ILocaleField } from '../../translations';

export interface IIndicator extends IEntity, IActivable {
  name: string;
  communityType: ICommunityType | undefined;
  localeName: ILocaleField[];
}

export class Indicator extends Entity implements IIndicator {
  name: string;
  isActive: boolean;
  communityType: ICommunityType | undefined;
  localeName: ILocaleField[] = [];

  constructor(data: IIndicator) {
    super(data);
    this.name = data.name;
    this.isActive = data.isActive;
    this.communityType = data.communityType
      ? new CommunityType(data.communityType)
      : undefined;
    this.localeName = data.localeName;
  }
}
