import { Content, IContent } from "../content";
import { ILocaleField } from "../../translations";

export interface IFreeContent extends IContent {
  text:string;
  localeText: ILocaleField[];
}

export class FreeContent extends Content implements IFreeContent{
  public text: string;
  public localeText: ILocaleField[];

  constructor(data: IFreeContent) {
    super(data);
    this.text = data.text;
    this.localeText = data.localeText
  }
}
