import {Entity, IEntity} from "../entity";
import {ContentType, IContentType} from "./content";

export interface IEmailTemplate extends IEntity {
  name: string;
  type: IContentType | null;
}

export class EmailTemplate extends Entity implements IEmailTemplate {
  public name: string;
  type: IContentType | null;

  constructor(data: IEmailTemplate) {
    super(data);
    this.name = data.name;
    this.type = data.type
      ? new ContentType(data.type)
      : null;
  }
}
