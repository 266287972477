import { Injectable, signal } from '@angular/core';
import { TranslationsViewModel } from '../view-models/common/translations-vm';
import { TranslateService } from '@ngx-translate/core';
import { Title } from '@angular/platform-browser';
import { toTitleCase } from '../Utils';

@Injectable({
  providedIn: 'root'
})
export class DynamicTitleService {
  private currentTitle = signal<string>('');
  private currentTitleKey = signal<string>('routes.admin.admin');
  private translationsVm: TranslationsViewModel;
  private translations =signal<{ [x: string]: string }>({});

  private keys = [
    'routes.admin.admin',
    'routes.admin.cityhalls',
    'routes.admin.companies',
    'routes.admin.communities',
    'routes.admin.invoiceLevels',
    'routes.admin.populationLevels',
    'routes.admin.sectors',
    'routes.admin.communityTypes',
    'routes.admin.indicators',
    'routes.admin.characteristics',
    'routes.admin.users',
    'routes.admin.activities',
    'routes.admin.resources',
    'routes.admin.assistances',
    'routes.admin.incriptions',
    'routes.admin.newsletters',
    'routes.admin.freeContents',
    'routes.admin.risks',
    'routes.admin.initiatives',
    'routes.admin.projects',
    'routes.admin.linesOfCredit',
    'routes.main.company',
    'routes.main.cityhall',
    'routes.main.xunta',
    'routes.not-found',
    'menuBarMessage'
  ];

  constructor(
    private translate: TranslateService,
    private titleService: Title
  ) {
    this.translationsVm = new TranslationsViewModel(this.translate, [], true);

    this.translate.onLangChange.subscribe(() => {
      this.translations.set(this.translationsVm.getKeys(this.keys));
      this.setDynamicTitle(this.currentTitleKey());
    });
  }

  setDynamicTitle(titleKey: string): void {
    const translation = this.translations()[titleKey];
    if (translation) {
      this.currentTitleKey.set(titleKey);
      this.currentTitle.set(translation);
    } else {
      this.currentTitleKey.set('menuBarMessage');
      this.currentTitle.set(this.translations()['menuBarMessage']);
      console.log('No translation found for titlekey: ', titleKey);
    }
    this.titleService.setTitle(toTitleCase(this.currentTitle()));
  }
}
