import { ContentType } from "./content";
import { ContentTypeType } from "../../types/content-type-type";

export class ContentTypeData {
  static types: ContentType[] = [
    new ContentType({ id: "activity", name: "activity", createdAt: new Date(), updatedAt: new Date() }),
    new ContentType({ id: "resource", name: "resource", createdAt: new Date(), updatedAt: new Date() }),
    new ContentType({ id: "newsletter", name: "newsletter", createdAt: new Date(), updatedAt: new Date() }),
    new ContentType({ id: "other", name: "other", createdAt: new Date(), updatedAt: new Date() }),
  ];

  static getAllTypes(): ContentType[] {
    return ContentTypeData.types;
  }

  static getIconClass(type: ContentTypeType): string {
    switch (type) {
      case "activity":
        return "pi pi-calendar";
      case "resource":
        return "pi pi-file";
      case "newsletter":
        return "pi pi-receipt";
      default:
        return "pi pi-exclamation-circle";
    }
  }
}
