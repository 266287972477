import { Injectable } from '@angular/core';
import {ApiService} from "./api-service.service";
import {environment} from "../../../environments/environment";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import { IFreeContentCommand } from '../models/commands';
import {IEntityList} from "../models/entities/entity-list";
import {FreeContent, IFreeContent} from "../models/entities/contents";

@Injectable({
  providedIn: 'root'
})
export class FreeContentService extends ApiService {
  private readonly url = `${environment.api.baseUrl}/free-contents`;

  getAll(
    langCode: string,
    filters: string | null = null
  ): Observable<IEntityList<IFreeContent>> {
    const url = `${this.url}?languageCode=${langCode}${filters ? `&filterBase64=${filters}` : ''}`;
    return this.get<IEntityList<IFreeContent>>(url).pipe(
      map(entityList => ({
        ...entityList,
        items: entityList.listObject.map(item => new FreeContent(item))
      }))
    );
  }

  getById(id: string, langCode: string): Observable<IFreeContent> {
    const url = `${this.url}/${id}?languageCode=${langCode}`;
    return this.get<IFreeContent>(url).pipe(
      map(fc => new FreeContent(fc))
    );
  }

  create(freeContent: IFreeContentCommand): Observable<FreeContent> {
    return this.post<IFreeContent>(this.url, freeContent).pipe(
      map(fc => new FreeContent(fc))
    );
  }

  update(id: string, freeContent: IFreeContentCommand): Observable<FreeContent> {
    return this.put<IFreeContent>(`${this.url}/${id}`, freeContent).pipe(
      map(fc => new FreeContent(fc))
    );
  }

  remove(id: string): Observable<boolean> {
    return this.delete(`${this.url}/${id}`);
  }
}
