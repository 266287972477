import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';

import { ApiService } from './api-service.service';
import { environment } from '../../../environments/environment';
import {IUser, User} from "../models/entities/users";

@Injectable({
  providedIn: 'root',
})
export class UsersService extends ApiService {
  private readonly url = `${environment.api.baseUrl}/users`;

  getAll(
    langCode: string,
    filters: string | null = null
  ): Observable<Array<IUser>> {
    const url = `${this.url}?languageCode=${langCode}${filters ? `&filterBase64=${filters}` : ''}`;
    return this.get<Array<IUser>>(url);
  }

  toggleIsActive(
    id: string,
    command: { isActive: boolean }
  ): Observable<{ isActive: boolean }> {
    return this.patch<{ isActive: boolean }>(`${this.url}/${id}`, command);
  }

  getById(id: string, langCode: string): Observable<User> {
    const url = `${this.url}/${id}?languageCode=${langCode}`;
    return this.get<IUser>(url).pipe(
      map((u: IUser) => new User(u))
    );
  }

  getMyProfile(): Observable<User> {
    const url = `${this.url}/me`;
    return this.get<IUser>(url).pipe(
      map((u: IUser) => new User(u))
    );
  }

  create(user: any): Observable<User> {
    return this.post<IUser>(this.url, user).pipe(
      map((u: IUser) => new User(u))
    );
  }

  update(id: string, user: any): Observable<User> {
    return this.put<IUser>(`${this.url}/${id}`, user).pipe(
      map((u: IUser) => new User(u))
    );
  }

  updateMyProfile(user: any): Observable<User> {
    return this.put<IUser>(`${this.url}/me`, user).pipe(
      map((u: IUser) => new User(u))
    );
  }

  remove(id: string): Observable<boolean> {
    return this.delete(`${this.url}/${id}`);
  }
}
