import { Injectable } from '@angular/core';
import { ApiService } from './api-service.service';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import {map} from "rxjs/operators";
import { ICommunityCommand } from '../models/commands';
import {IEntityList} from "../models/entities/entity-list";
import {Community, ICommunity} from "../models/entities/organizations";

@Injectable({
  providedIn: 'root',
})
export class CommunityService extends ApiService {
  private readonly url = `${environment.api.baseUrl}/communities`;

  getAll(langCode: string, filters: string | null = null): Observable<IEntityList<ICommunity>> {
    const url = `${this.url}?languageCode=${langCode}${filters ? `&filterBase64=${filters}` : ''}`;
    return this.get<IEntityList<ICommunity>>(url).pipe(
      map(entityList => ({
        ...entityList,
        items: entityList.listObject.map(item => new Community(item))
      }))
    );
  }

  create(communityType: ICommunityCommand): Observable<Community> {
    return this.post<ICommunity>(this.url, communityType).pipe(
      map((c) => new Community(c))
    );
  }

  remove(id: string): Observable<boolean> {
    return this.delete(`${this.url}/${id}`);
  }

  getById(id: string, langCode: string): Observable<Community> {
    const url = `${this.url}/${id}?languageCode=${langCode}`;
    return this.get<ICommunity>(url).pipe(
      map(community => new Community(community))
    );
  }

  update(
    id: string,
    community: ICommunityCommand
  ): Observable<Community> {
    return this.put<ICommunity>(`${this.url}/${id}`, community).pipe(
      map((c) => new Community(c))
    );
  }

  updateMyCommunity(community: ICommunityCommand): Observable<Community> {
    return this.put<ICommunity>(`${this.url}/me`, community).pipe(
      map((c) => new Community(c))
    );
  }

  toggleIsActive(
    id: string,
    command: { isActive: boolean }
  ): Observable<{ isActive: boolean }> {
    return this.patch<{ isActive: boolean }>(`${this.url}/${id}`, command);
  }
}
