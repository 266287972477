import { CommunityType } from './community-type';
import { Indicator } from './indicator';
import { IOrganizationUnit, OrganizationUnit } from '../organization-unit';
import { IActivable } from '../../entity';
import { ILocation } from '../geocodingInfo';

export interface ICommunity extends IOrganizationUnit, IActivable {
  website: string;
  postalCode: string;
  location: ILocation;
  locality: string;
  province: string;
  cif: string | undefined;
  communityType: CommunityType | undefined;
  primaryIndicator: Indicator | undefined;
  secondaryIndicator: Indicator | undefined;
}

export class Community extends OrganizationUnit implements ICommunity {
  isActive: boolean;
  website: string;
  postalCode: string;
  location: ILocation;
  locality: string;
  province: string;
  cif: string | undefined;
  communityType: CommunityType | undefined;
  primaryIndicator: Indicator | undefined;
  secondaryIndicator: Indicator | undefined;

  constructor(data: ICommunity) {
    super(data);
    this.isActive = data.isActive;
    this.website = data.website;
    this.postalCode = data.postalCode;
    this.location = data.location;
    this.locality = data.locality;
    this.province = data.province;
    this.cif = data.cif;
    this.communityType = data.communityType
      ? new CommunityType(data.communityType)
      : undefined;
    this.primaryIndicator = data.primaryIndicator
      ? new Indicator(data.primaryIndicator)
      : undefined;
    this.secondaryIndicator = data.secondaryIndicator
      ? new Indicator(data.secondaryIndicator)
      : undefined;
  }
}
