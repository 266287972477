import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { translateMonthDate } from '../translate-month-date.utils';

@Pipe({
  name: 'galiciaDate',
  standalone: true,
})
export class GaliciaDatePipe implements PipeTransform {
  transform(value: any, lang: string = "es",
    shortMonth: boolean = false, withTime: boolean = false,
    locale: string = "es-ES"): string | null {
    if (!value) return '';

    const date = value instanceof Date ? value : new Date(value);

    if (isNaN(date.getTime())) {
      console.error(`Invalid date value provided: "${value}"`);
      return '';
    }

    const datePipe = new DatePipe(locale);
    let formattedDate = shortMonth
      ? datePipe.transform(date, 'd MMM y')
      : datePipe.transform(date, 'd MMMM y');

    formattedDate = withTime
      ? datePipe.transform(date, 'd MMM HH:mm')
      : formattedDate;

    if (lang === 'gl' && formattedDate) {
      formattedDate = translateMonthDate(formattedDate, shortMonth);
    }

    return formattedDate || value;
  }
}
