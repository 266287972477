export interface ILocaleField {
  langCode: string;
  text: string;
}

export class LocaleField implements ILocaleField {
  langCode: string;
  text: string;

  constructor(data: ILocaleField) {
    this.langCode = data.langCode;
    this.text = data.text;
  }
}
