import { Entity, IEntity } from '../entity';

export interface ILanguage extends IEntity {
  code: string;
}

export class Language extends Entity implements ILanguage {
  code: string;

  constructor(data: ILanguage) {
    super(data);
    this.code = data.code;
  }
}
