import {Characteristic} from '../organizations';
import {Indicator} from '../organizations';
import {InvoiceLevel} from '../organizations';
import {PopulationLevel} from '../organizations';
import {Sector} from '../organizations';
import {Company} from "../organizations";
import {CityHall} from "../organizations";
import {Community} from "../organizations";
import {OrganizationUnit} from "../organizations";
import {IPartialSelection, PartialSelection} from "../../../services/partial-selections/partial-selection";
import {SelectionStatusData} from "../../../services/partial-selections/selection-status";

export interface ITarget {
  organizationUnits: Array<any>; // temporal
  sectors: Array<Sector>;
  invoiceLevels: Array<InvoiceLevel>;
  populationLevels: Array<PopulationLevel>;
  characteristics: Array<Characteristic>;
  indicators: Array<Indicator>;
  selections: ITargetSelections | undefined;

  get companySelections(): PartialSelection<Company> | undefined;
  get cityHallSelections(): PartialSelection<CityHall> | undefined;
  getCommunitySelection(typeId: string): PartialSelection<Community> | undefined;
  get companies(): Array<Company> | undefined;
  get cityHalls(): Array<CityHall> | undefined;
  getCommunities(communityTypeId: string | undefined): Array<Community> | undefined;
  getCommunityIndicators(communityTypeId: string | undefined): Array<Indicator>;

  get hasAnyCompanyAttribute():boolean;
  get hasAnyCityHallAttribute():boolean;
}

export interface ITargetSelections {
  companySelection: PartialSelection<Company> | undefined;
  cityHallSelection: PartialSelection<CityHall> | undefined;
  communitySelection: PartialSelection<Community>[];
}

export class Target implements ITarget {
  organizationUnits: Array<OrganizationUnit>;
  sectors: Array<Sector>;
  invoiceLevels: Array<InvoiceLevel>;
  populationLevels: Array<PopulationLevel>;
  characteristics: Array<Characteristic>;
  indicators: Array<Indicator>;
  selections: ITargetSelections | undefined;

  constructor(data: ITarget, selections?: IPartialSelection<any>[]) {
    this.organizationUnits = data.organizationUnits.map((el) => {
      let item: OrganizationUnit = new OrganizationUnit(el);
      if (el.type == 'company') {
        item = new Company(el);
      }
      if (el.type == 'cityhall') {
        item = new CityHall(el);
      }
      if (el.type == 'community') {
        item = new Community(el);
      }
      return item;
    })
    this.sectors = data.sectors.map((el) => new Sector(el));
    this.invoiceLevels = data.invoiceLevels.map((el) => new InvoiceLevel(el));
    this.populationLevels = data.populationLevels.map(
      (el) => new PopulationLevel(el)
    );
    this.characteristics = data.characteristics.map(
      (el) => new Characteristic(el)
    );
    this.indicators = data.indicators.map((el) => new Indicator(el));

    if (selections) {
      let companySelection: PartialSelection<Company> | undefined;
      let cityHallSelection: PartialSelection<CityHall> | undefined;
      let communitiesSelection: PartialSelection<Community>[] = [];

      selections.forEach((el) => {
        switch (el.organizationTypeId) {
          case "company":
            companySelection = new PartialSelection<Company>(SelectionStatusData.getStatusByName(el.status));
            companySelection.items = el.items.map(x => new Company(x));
            break;
          case "cityhall":
            cityHallSelection = new PartialSelection<CityHall>(SelectionStatusData.getStatusByName(el.status));
            cityHallSelection.items = el.items.map(x => new CityHall(x));
            break;
          case "community":
            let communitySelection = new PartialSelection<Community>(SelectionStatusData.getStatusByName(el.status));
            communitySelection.items = el.items.map(x => new Community(x));
            communitySelection.object = el.communityTypeId;
            communitiesSelection.push(communitySelection);
            break;
        }
      });

      this.selections = {
        companySelection: companySelection,
        cityHallSelection: cityHallSelection,
        communitySelection: communitiesSelection
      }
    }
  }

  public get companySelections(): PartialSelection<Company> | undefined {
    return this.selections?.companySelection;
  }

  public get companies() {
    return this.companySelections?.items ?? [];
  }

  public get cityHallSelections(): PartialSelection<CityHall> | undefined {
    return this.selections?.cityHallSelection;
  }

  public get cityHalls() {
    return this.cityHallSelections?.items ?? [];
  }

  public getCommunitySelection(typeId: string): PartialSelection<Community> | undefined {
    let selection = this.selections?.communitySelection
      .filter(x => x.object == typeId);
    if (selection) {
      return selection[0];
    }
    return undefined;
  }

  public getCommunities(communityTypeId: string | undefined) {
    const all = this.getCommunitySelection(communityTypeId ?? '')?.items ?? [];
    return all.filter(x => x.communityType?.id === communityTypeId);
  }

  public getCommunityIndicators(communityTypeId: string | undefined) {
    if (communityTypeId) {
      return this.indicators.filter(x => x.communityType?.id === communityTypeId);
    }
    return [];
  }

  public get hasAnyCompanyAttribute():boolean {
    return !!(this.selections?.companySelection ||
      (this.invoiceLevels && this.invoiceLevels?.length > 0) ||
      (this.sectors && this.sectors?.length > 0));

  }

  public get hasAnyCityHallAttribute():boolean {
    return !!(this.selections?.cityHallSelection ||
      (this.populationLevels && this.populationLevels?.length > 0) ||
      (this.characteristics && this.characteristics?.length > 0));

  }

}
