import { Routes } from '@angular/router';
import { AppPermissions } from '../../auth/models/app-permissions';
import { roleGuard } from '../../auth/guards';
import { titleResolver } from '../../shared/Utils/resolvers/title.resolver';

export const mainPagesRoutes: Routes = [
  {
    path: 'companies/me',
    data: {
      roles: [AppPermissions.COMPANY],
      titleTranslationKey: 'routes.main.company',
    },
    resolve: {
      titleResolver,
    },
    canActivate: [roleGuard],
    loadComponent: async () =>
      await import('./company-main-page/company-main-page.component').then(
        (c) => c.CompanyMainPageComponent
      ),
  },
  {
    path: 'cityhalls/me',
    title: 'City Hall',
    data: {
      roles: [AppPermissions.CITYHALL],
      titleTranslationKey: 'routes.main.cityhall',
    },
    resolve: {
      titleResolver,
    },
    canActivate: [roleGuard],
    loadComponent: async () =>
      await import('./city-hall-main-page/city-hall-main-page.component').then(
        (c) => c.CityHallMainPageComponent
      ),
  },
  {
    path: 'xunta',
    title: 'Xunta',
    data: {
      roles: [AppPermissions.XUNTA],
      titleTranslationKey: 'routes.main.xunta',
    },
    resolve: {
      titleResolver,
    },
    canActivate: [roleGuard],
    loadComponent: async () =>
      await import('./xunta-main-page/xunta-main-page.component').then(
        (c) => c.XuntaMainPageComponent
      ),
  }
];
