import { ILocaleField } from '../../translations';
import {Entity, IActivable, IEntity} from "../../entity";

export interface IInvoiceLevel extends IEntity, IActivable {
  name: string;
  order: number;
  localeName: ILocaleField[];
}

export class InvoiceLevel extends Entity implements IInvoiceLevel {
  name: string;
  isActive: boolean;
  order: number;
  localeName: ILocaleField[] = [];

  constructor(data: IInvoiceLevel) {
    super(data);
    this.name = data.name;
    this.isActive = data.isActive;
    this.order = data.order;
    this.localeName = data.localeName;
  }
}
