import { IEntity, Entity } from "../entity";

export interface IValidityPeriod extends IEntity {
  endDate: string;
  startDate: string;
}

export class ValidityPeriod extends Entity implements IValidityPeriod {
  endDate: string;
  startDate: string;

  constructor(data: IValidityPeriod) {
    super(data);
    this.endDate = data.endDate;
    this.startDate = data.startDate;
  }
}
