import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { ILocaleField } from '../models/entities/translations';

export function updateTranslationsForm(translations: FormArray, localeData?: ILocaleField[], fieldName?: string) {
  if (localeData === undefined) return;
  localeData.forEach((translation) => {
    const control = translations.controls.find(
      (c) => c.get('lang')?.value === translation.langCode
    );
    if (control) {
      control.patchValue({
        [fieldName!]: translation.text,
      });
    }
  });
}

export function resetTranslationsForm(
  translations: FormArray,
  languages: string[],
  fields: string[]
) {
  translations.patchValue(
    languages.map((lang) => {
      const translation = { lang: lang } as { [key: string]: any };
      fields.forEach((field) => {
        translation[field] = '';
      });
      return translation;
    })
  );
}

export function mapTranslationsForm(translations: any[], field: string) {
  return translations
    .filter((t: { lang: string; [key: string]: string }) => t[field] !== '')
    .map((t: { lang: string; [key: string]: string }) => ({
      langCode: t.lang,
      text: t[field],
    }));
}

export function addLanguageControls(
  translations: FormArray,
  languages: string[],
  fields: string[],
  fieldsWithoutRequired: string[] = []
) {
  languages.forEach((langCode) => {
    const languageGroup = new FormGroup<{ [key: string]: FormControl<string | null> }>({
      lang: new FormControl(langCode),
    });

    fields.forEach((field) => {
      languageGroup.addControl(field, new FormControl('', Validators.required));
    });

    fieldsWithoutRequired.forEach((field) => {
      languageGroup.addControl(field, new FormControl(''));
    });

    translations.push(languageGroup);
  });
}
