import { Injectable } from '@angular/core';
import { ApiService } from './api-service.service';
import { environment } from '../../../environments/environment';
import { map, Observable } from 'rxjs';
import { ICommunityTypeCommand } from '../models/commands';
import {CommunityType, ICommunityType} from "../models/entities/organizations";
import {IEntityList} from "../models/entities/entity-list";

@Injectable({
  providedIn: 'root',
})
export class CommunityTypeService extends ApiService {
  private readonly url = `${environment.api.baseUrl}/communities/types`;

  getAll(
    langCode: string,
    filters: string | null = null
  ): Observable<IEntityList<ICommunityType>> {
    const url = `${this.url}?languageCode=${langCode}${filters ? `&filterBase64=${filters}` : ''}`;
    return this.get<IEntityList<ICommunityType>>(url);
  }

  create(
    communityType: ICommunityTypeCommand
  ): Observable<CommunityType> {
    return this.post<ICommunityType>(this.url, communityType).pipe(
      map((ct) => new CommunityType(ct))
    );
  }

  remove(id: string): Observable<boolean> {
    return this.delete(`${this.url}/${id}`);
  }

  getById(id: string, langCode: string): Observable<ICommunityType> {
    const url = `${this.url}/${id}?languageCode=${langCode}`;
    return this.get<ICommunityType>(url);
  }

  update(
    id: string,
    communityType: ICommunityTypeCommand
  ): Observable<CommunityType> {
    return this.put<ICommunityType>(`${this.url}/${id}`, communityType).pipe(
      map((ct) => new CommunityType(ct))
    );
  }

  toggleIsActive(
    id: string,
    command: { isActive: boolean }
  ): Observable<{ isActive: boolean }> {
    return this.patch<{ isActive: boolean }>(`${this.url}/${id}`, command);
  }
}
