import { Routes } from '@angular/router';
import { roleGuard } from '../../auth/guards';
import { AppPermissions } from '../../auth/models/app-permissions';
import { titleResolver } from '../../shared/Utils/resolvers/title.resolver';

export const adminPagesRoutes: Routes = [
  {
    path: 'cityhalls',
    canActivate: [roleGuard],
    data: {
      roles: [AppPermissions.TECHNICALOFFICE],
      titleTranslationKey: 'routes.admin.cityhalls',
    },
    resolve: {
      titleResolver,
    },
    loadComponent: async () =>
      await import('./cityhalls-page/cityhalls-page.component').then(
        (c) => c.CityhallsPageComponent
      ),
  },
  {
    path: 'companies',
    canActivate: [roleGuard],
    data: {
      roles: [AppPermissions.TECHNICALOFFICE],
      titleTranslationKey: 'routes.admin.companies',
    },
    resolve: {
      titleResolver,
    },
    loadComponent: async () =>
      await import('./companies-page/companies-page.component').then(
        (c) => c.CompaniesPageComponent
      ),
  },
  {
    path: 'invoice-levels',
    canActivate: [roleGuard],
    data: {
      roles: [AppPermissions.TECHNICALOFFICE],
      titleTranslationKey: 'routes.admin.invoiceLevels',
    },
    resolve: {
      titleResolver,
    },
    loadComponent: async () =>
      await import('./invoice-levels-page/invoice-levels-page.component').then(
        (c) => c.InvoiceLevelsPageComponent
      ),
  },
  {
    path: 'population-levels',
    canActivate: [roleGuard],
    data: {
      roles: [AppPermissions.TECHNICALOFFICE],
      titleTranslationKey: 'routes.admin.populationLevels',
    },
    resolve: {
      titleResolver,
    },
    loadComponent: async () =>
      await import(
        './population-levels-page/population-levels-page.component'
      ).then((c) => c.PopulationLevelsPageComponent),
  },
  {
    path: 'sectors',
    canActivate: [roleGuard],
    data: {
      roles: [AppPermissions.TECHNICALOFFICE],
      titleTranslationKey: 'routes.admin.sectors',
    },
    resolve: {
      titleResolver,
    },
    loadComponent: async () =>
      await import('./sectors-page/sectors-page.component').then(
        (c) => c.SectorsPageComponent
      ),
  },
  {
    path: 'communities',
    canActivate: [roleGuard],
    data: {
      roles: [AppPermissions.TECHNICALOFFICE],
      titleTranslationKey: 'routes.admin.communities',
    },
    resolve: {
      titleResolver,
    },
    loadComponent: async () =>
      await import('./communities-page/communities-page.component').then(
        (c) => c.CommunitiesPageComponent
      ),
  },
  {
    path: 'communities-types',
    canActivate: [roleGuard],
    data: {
      roles: [AppPermissions.TECHNICALOFFICE],
      titleTranslationKey: 'routes.admin.communityTypes',
    },
    resolve: {
      titleResolver,
    },
    loadComponent: async () =>
      await import(
        './communities-types-page/communities-types-page.component'
      ).then((c) => c.CommunitiesTypesPageComponent),
  },
  {
    path: 'indicators',
    canActivate: [roleGuard],
    data: {
      roles: [AppPermissions.TECHNICALOFFICE],
      titleTranslationKey: 'routes.admin.indicators',
    },
    resolve: {
      titleResolver,
    },
    loadComponent: async () =>
      await import('./indicators-page/indicators-page.component').then(
        (c) => c.IndicatorsPageComponent
      ),
  },
  {
    path: 'characteristics',
    canActivate: [roleGuard],
    data: {
      roles: [AppPermissions.TECHNICALOFFICE],
      titleTranslationKey: 'routes.admin.characteristics',
    },
    resolve: {
      titleResolver,
    },
    loadComponent: async () =>
      await import(
        './characteristics-page/characteristics-page.component'
      ).then((c) => c.CharacteristicsPageComponent),
  },
  {
    path: 'users',
    canActivate: [roleGuard],
    data: {
      roles: [AppPermissions.TECHNICALOFFICE],
      titleTranslationKey: 'routes.admin.users',
    },
    resolve: {
      titleResolver,
    },
    loadComponent: async () =>
      await import('./users-page/users-page.component').then(
        (c) => c.UsersPageComponent
      ),
  },
  {
    path: 'activities',
    canActivate: [roleGuard],
    data: {
      roles: [AppPermissions.TECHNICALOFFICE],
      titleTranslationKey: 'routes.admin.activities',
    },
    resolve: {
      titleResolver,
    },
    loadComponent: async () =>
      await import('./activities-page/activities-page.component').then(
        (c) => c.ActivitiesPageComponent
      ),
  },
  {
    path: 'resources',
    canActivate: [roleGuard],
    data: {
      roles: [AppPermissions.TECHNICALOFFICE],
      titleTranslationKey: 'routes.admin.resources',
    },
    resolve: {
      titleResolver,
    },
    loadComponent: async () =>
      await import('./resources-page/resources-page.component').then(
        (c) => c.ResourcesPageComponent
      ),
  },
  {
    path: 'assistances',
    canActivate: [roleGuard],
    data: {
      roles: [AppPermissions.TECHNICALOFFICE],
      titleTranslationKey: 'routes.admin.assistances',
    },
    resolve: {
      titleResolver,
    },
    loadComponent: async () =>
      await import('./assistances-page/assistances-page.component').then(
        (c) => c.AssistancesPageComponent
      ),
  },
  {
    path: 'inscriptions',
    canActivate: [roleGuard],
    data: {
      roles: [AppPermissions.TECHNICALOFFICE],
      titleTranslationKey: 'routes.admin.incriptions',
    },
    resolve: {
      titleResolver,
    },
    loadComponent: async () =>
      await import('./inscriptions-page/inscriptions-page.component').then(
        (c) => c.InscriptionsPageComponent
      ),
  },
  {
    path: 'newsletters',
    data: {
      roles: [AppPermissions.TECHNICALOFFICE],
      titleTranslationKey: 'routes.admin.newsletters',
    },
    resolve: {
      titleResolver,
    },
    canActivate: [roleGuard],
    loadComponent: async () =>
      await import('./newsletters-page/newsletters-page.component').then(
        (c) => c.NewslettersPageComponent
      ),
  },
  {
    path: 'free-contents',
    data: {
      roles: [AppPermissions.TECHNICALOFFICE],
      titleTranslationKey: 'routes.admin.freeContents',
    },
    resolve: {
      titleResolver,
    },
    canActivate: [roleGuard],
    loadComponent: async () =>
      await import('./free-content-page/free-content-page.component').then(
        (c) => c.FreeContentPageComponent
      ),
  },
  {
    path: 'risks',
    data: {
      roles: [AppPermissions.TECHNICALOFFICE],
      titleTranslationKey: 'routes.admin.risks',
    },
    resolve: {
      titleResolver,
    },
    canActivate: [roleGuard],
    loadComponent: async () =>
      await import('./risks-page/risks-page.component').then(
        (c) => c.RisksPageComponent
      ),
  },
  {
    path: 'initiatives',
    data: {
      roles: [AppPermissions.TECHNICALOFFICE],
      titleTranslationKey: 'routes.admin.initiatives',
    },
    resolve: {
      titleResolver,
    },
    canActivate: [roleGuard],
    loadComponent: async () =>
      await import('./initiatives-page/initiatives-page.component').then(
        (c) => c.InitiativesPageComponent
      ),
  },
  {
    path: 'projects',
    data: {
      roles: [AppPermissions.TECHNICALOFFICE],
      titleTranslationKey: 'routes.admin.projects',
    },
    resolve: {
      titleResolver,
    },
    canActivate: [roleGuard],
    loadComponent: async () =>
      await import('./projects-page/projects-page.component').then(
        (c) => c.ProjectsPageComponent
      ),
  },
  {
    path: 'lines-of-credit',
    data: {
      roles: [AppPermissions.TECHNICALOFFICE],
      titleTranslationKey: 'routes.admin.linesOfCredit',
    },
    resolve: {
      titleResolver,
    },
    canActivate: [roleGuard],
    loadComponent: async () =>
      await import('./lines-of-credit-page/lines-of-credit-page.component').then(
        (c) => c.LinesOfCreditPageComponent
      ),
  },
  {
    path: 'test',
    title: 'test',
    data: {
      roles: [AppPermissions.TECHNICALOFFICE],
    },
    canActivate: [roleGuard],
    loadComponent: async () =>
      await import('./test-page/test-page.component').then(
        (c) => c.TestPageComponent
      ),
  },
];
