import {TableLazyLoadEvent} from 'primeng/table';
import {Filter} from '../models/entities/translations';

function convertDateValuesInFilters(filters: any): any {
  if(filters) {
    Object.keys(filters).forEach((key) => {
      const filterArray = filters[key];
      if (Array.isArray(filterArray)) {
        filterArray.forEach((item: any) => {
          if (item.value instanceof Date) {
            item.value = formatToISOWithoutTimezone(item.value);
          }
        });
      }
    });
  }
  return filters;
}

function formatToISOWithoutTimezone(date: Date): string {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}T00:00:00.000Z`;
}

export function getBase64Filters(
  value: TableLazyLoadEvent | Filter | undefined
): string | null {
  if (value) {
    value.filters = convertDateValuesInFilters(value.filters);
    return btoa(new TextEncoder().encode(JSON.stringify(value))
      .reduce((acc, byte) => acc + String.fromCharCode(byte), ""));
  }
  return null;
}

function convertDatesToUTC(obj:any) {
  if (obj === null || typeof obj !== "object") {
    return obj;
  }

  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const value = obj[key];

      if (value instanceof Date) {
        obj[key] = formatToISOWithoutTimezone(value);
      }
      /*else if (typeof value === "string" && !isNaN(Date.parse(value))) {
        obj[key] = formatToISOWithoutTimezone(new Date(value));
      } */
      else if (Array.isArray(value)) {
        value.forEach((item, index) => {
          if (item && typeof item === "object") {
            value[index] = convertDatesToUTC(item);
          }
        });
      } else if (typeof value === "object") {
        convertDatesToUTC(value);
      }
    }
  }

  return obj;
}

export function adaptInFilterToContains(
  filters: any,
  fieldNames: string[]
): any {
  const converted = convertDatesToUTC(filters);
  const adaptedFilters = JSON.parse(JSON.stringify(converted));
  if (!adaptedFilters.hasOwnProperty("filters")) return;

  fieldNames.forEach((fieldName) => {
    const newFilterArray: {
      value: any;
      matchMode: string;
      operator: string;
    }[] = [];

    if (
      adaptedFilters?.filters[fieldName]?.length > 0 &&
      Array.isArray(adaptedFilters?.filters[fieldName][0]?.value)
    ) {
      adaptedFilters?.filters[fieldName][0].value.forEach((value: string) => {
        newFilterArray.push({
          value: value,
          matchMode: 'equals',
          operator: 'or',
        });
      });

      if (newFilterArray.length > 0) {
        adaptedFilters.filters[fieldName] = newFilterArray;
      } else {
        delete adaptedFilters.filters[fieldName];
      }
    } else {
      delete adaptedFilters.filters[fieldName];
    }
  });

  return adaptedFilters;
}
