import { ILocaleField } from '../../translations';
import {Entity, IActivable, IEntity} from "../../entity";

export interface ICharacteristic extends IEntity, IActivable {
  name: string;
  localeName: ILocaleField[];
}

export class Characteristic extends Entity implements ICharacteristic {
  name: string;
  isActive: boolean;
  localeName: ILocaleField[] = [];

  constructor(data: ICharacteristic) {
    super(data);
    this.name = data.name;
    this.isActive = data.isActive;
    this.localeName = data.localeName;
  }
}
