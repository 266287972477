import {ResourceFile} from "./resource-file";
import {Content, IContent} from "../content";

export interface IResource extends IContent {
  files: ResourceFile[] | null;
  isNew: boolean;
}

export class Resource extends Content implements IResource {
  files: ResourceFile[] | null;

  get isNew(): boolean {
    return (this.files && this.files.length > 0)
      ? this.files.every(file => file.isNew)
      : false;
  }

  get hasFiles(): boolean {
    return this.files !== null && this.files.length > 0;
  }

  get onlyOneFile(): boolean {
    return this.files !== null && this.files.length === 1;
  }

  get mostRecentFile(): ResourceFile | null {
    if (!this.hasFiles) {
      return null;
    }

    return this.files!.reduce((mostRecent, currentFile) => {
      const mostRecentDate = mostRecent.lastUpdate;
      const currentDate = currentFile.lastUpdate;

      return currentDate > mostRecentDate ? currentFile : mostRecent;
    });
  }

  constructor(data: IResource) {
    super(data);
    this.files = data.files
      ? data.files
        .map(file => new ResourceFile(file))
        .sort((a, b) => a.order - b.order)
      : null;
  }
}
