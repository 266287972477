import { Entity, IEntity } from '../entity';

export interface IOrganizationUnitType extends IEntity {
  name: string;
  nameKey?: string;
}

export class OrganizationUnitType extends Entity
  implements IOrganizationUnitType
{
  name: string;
  get nameKey(): string {
    return `organizationUnitTypes.${this.name}`;
  }

  constructor(data: IOrganizationUnitType) {
    super(data);
    this.name = data.name;
  }
}


