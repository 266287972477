import {Injectable} from '@angular/core';
import {environment} from "../../../environments/environment";

@Injectable({
    providedIn: 'root'
})
export class GoogleMapsLoaderService {
    private scriptLoaded = false;
    private loadScriptPromise: Promise<void> | null = null;

    loadGoogleMaps(): Promise<void> {
        if (this.scriptLoaded) {
            return Promise.resolve();
        }

        if (this.loadScriptPromise) {
            return this.loadScriptPromise;
        }

        const scriptUrl = `https://maps.googleapis.com/maps/api/js?key=${environment.googleMapsApiKey}&libraries=geometry,marker&v=weekly`;

        this.loadScriptPromise = new Promise((resolve, reject) => {
            const script = document.createElement('script');
            script.src = scriptUrl;
            script.async = true;
            script.defer = true;
            script.onload = () => {
                this.scriptLoaded = true;
                resolve();
            };
            script.onerror = () => {
                this.loadScriptPromise = null;
                reject(new Error('Failed to load Google Maps script'));
            };
            document.head.appendChild(script);
        });

        return this.loadScriptPromise;
    }
}
