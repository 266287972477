export interface ISelectionStatus {
  name: string;
}

export class SelectionStatus {
  name: string;

  constructor(data: ISelectionStatus) {
    this.name = data.name;
  }

  equals(other: ISelectionStatus): boolean {
    return other.name === this.name;
  }
}

export class SelectionStatusData {
  static none: SelectionStatus = new SelectionStatus({name: 'none'});
  static all: SelectionStatus = new SelectionStatus({name: 'all'});

  public static getAllStatus() {
    return [
      this.none,
      this.all
    ]
  }

  public static getStatusByName(name: string): SelectionStatus {
    switch (name) {
      case 'none':
        return this.none;
      case 'all':
        return this.all;
    }
    return this.none;
  }
}
