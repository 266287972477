import { Injectable } from '@angular/core';
import { ApiService } from './api-service.service';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { IInvoiceLevelCommand } from '../models/commands';
import {IEntityList} from "../models/entities/entity-list";
import {IInvoiceLevel} from "../models/entities/organizations";


@Injectable({
  providedIn: 'root',
})
export class InvoiceLevelService extends ApiService {
  private readonly url = `${environment.api.baseUrl}/invoice-levels`;

  getAll(
    langCode: string,
    filters: string | null = null
  ): Observable<IEntityList<IInvoiceLevel>> {
    const url = `${this.url}?languageCode=${langCode}${filters ? `&filterBase64=${filters}` : ''}`;
    return this.get<IEntityList<IInvoiceLevel>>(url);
  }

  toggleIsActive(
    id: string,
    command: { isActive: boolean }
  ): Observable<{ isActive: boolean }> {
    return this.patch<{ isActive: boolean }>(`${this.url}/${id}`, command);
  }

  getById(id: string, langCode: string): Observable<IInvoiceLevel> {
    const url = `${this.url}/${id}?languageCode=${langCode}`;
    return this.get<IInvoiceLevel>(url);
  }

  create(
    invoiceLevel: IInvoiceLevelCommand
  ): Observable<IInvoiceLevel> {
    return this.post<IInvoiceLevel>(this.url, invoiceLevel);
  }

  update(
    id: string,
    invoiceLevel: IInvoiceLevelCommand
  ): Observable<IInvoiceLevel> {
    return this.put<IInvoiceLevel>(`${this.url}/${id}`, invoiceLevel);
  }

  remove(id: string): Observable<boolean> {
    return this.delete(`${this.url}/${id}`);
  }
}
