import { ILocaleField } from '../../translations';
import {Entity, IActivable, IEntity} from "../../entity";

export interface ISector extends IEntity, IActivable {
  name: string;
  cnae: number;
  localeName: ILocaleField[];
  localeDisplayName: string;
}

export class Sector extends Entity implements ISector {
  name: string;
  cnae: number;
  isActive: boolean;
  localeName: ILocaleField[] = [];
  localeDisplayName: string;

  constructor(data: ISector) {
    super(data);
    this.name = data.name;
    this.cnae = data.cnae;
    this.isActive = data.isActive;
    this.localeName = data.localeName;
    this.localeDisplayName = data.localeDisplayName;
  }
}
